import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { VscListFilter } from 'react-icons/vsc';

import { CompendiumContext } from '../../context/Compendium';
import scrollSmoothly from '../../utils/scrollSmoothly';
import { BREAK_POINT } from '../../constants';

const ArticleNavigation = ({ sections, disabled }) => {
  const [scrollCoefficient, setScrollCoefficient] = useState('0%');
  const [isOpen, setIsOpen] = useState(false);
  const { activeHeading } = useContext(CompendiumContext);

  useEffect(() => {
    document.addEventListener('scroll', handleArticleScroll);
    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.removeEventListener('scroll', handleArticleScroll);
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const handleArticleScroll = ({ currentTarget }) => {
    const coefficient = window.scrollY / (currentTarget.body.scrollHeight - window.innerHeight);

    setScrollCoefficient(coefficient.toFixed(3));
  };

  const handleBodyClick = ({ target }) => {
    if (
      window.innerWidth >= BREAK_POINT.lg ||
      target.closest('#open-article-navigation, #article-navigation')
    ) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <>
      <button
        id="open-article-navigation"
        type="button"
        onClick={() => setIsOpen(true)}
        className="absolute top-9 right-8 lg:hidden"
      >
        <VscListFilter size="24" />
      </button>
      <nav
        id="article-navigation"
        className={cn(
          'lg:sticky shrink-0 w-[173px] pt-10 bg-white h-dvh-fb overflow-y-auto no-scrollbar',
          'fixed top-0 right-0 shadow lg:shadow-none lg:translate-x-0 transition-transform duration-300 ease-out',
          { 'translate-x-[50vw]': !isOpen },
        )}
      >
        <ul className="flex flex-col justify-center min-h-full text-sm">
          {sections.map(({ type, id, name }) => (
            <li
              key={id}
              className={cn('pr-2', {
                'mb-6': type === 'title',
                'mb-2': type !== 'title',
                'pl-4': type !== 'subheading',
                'pl-7': type === 'subheading',
                'font-medium': type === 'title',
                'font-light': type === 'subheading',
                'bg-purple-100': activeHeading === id && !disabled,
                'hover:bg-purple-100': disabled,
              })}
            >
              {disabled ? (
                <span>{name}</span>
              ) : (
                <a href={`#${id}`} onClick={(event) => scrollSmoothly(event, id)}>
                  {name}
                </a>
              )}
            </li>
          ))}
        </ul>
        {!disabled && (
          <div
            className="absolute left-0 top-0 w-1 h-full bg-purple-border transition-[transform] ease-linear"
            style={{ transform: `scale(${scrollCoefficient}` }}
          />
        )}
      </nav>
    </>
  );
};

export default ArticleNavigation;
