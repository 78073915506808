const scrollSmoothly = (event, id) => {
  event.preventDefault();

  const element = document.getElementById(id);

  element?.scrollIntoView({ behavior: 'smooth' });
  window.history.pushState(null, '', `#${id}`);
};

export default scrollSmoothly;
