import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import cn from 'classnames';

import useAuth from '../../hooks/useAuth';
import { BREAK_POINT } from '../../constants';

const ArticleList = ({ articles, chosenArticlePath, isStructureOpen, setIsStructureOpen }) => {
  const { isAdmin } = useAuth();
  const { pathname } = useLocation();

  const handleListClick = () => {
    const isMobileLg = window.innerWidth < BREAK_POINT.lg;

    if (!isMobileLg || isStructureOpen) {
      return;
    }

    setIsStructureOpen(true);
  };

  const handleListItemClick = (event) => {
    const isMobileLg = window.innerWidth < BREAK_POINT.lg;

    if (!isMobileLg) {
      return;
    }

    if (isStructureOpen) {
      setIsStructureOpen(false);
    } else {
      event.preventDefault();
    }
  };

  return (
    <ul
      className="grow shrink-0 flex flex-col lg:w-60 border-purple-primary border-r-2 overflow-auto"
      onClick={handleListClick}
    >
      {articles.map(({ title, path, isPublic }) => (
        <li
          key={path}
          className={cn('shrink-0 font-bold border-b border-purple-primary', {
            'bg-purple-100': path === chosenArticlePath,
            'opacity-60': !isPublic && !isAdmin,
          })}
        >
          <Link
            className="block p-4 hover:no-underline"
            to={`/compendium/${path}`}
            onClick={handleListItemClick}
          >
            {title}
          </Link>
        </li>
      ))}
      {process.env.GATSBY_ENV === 'development' && (
        <Link
          className="mt-auto p-4 text-xs text-end"
          to={`/compendium/${pathname
            .replace(/\.pdf\/$/, '.md/')
            .split('/compendium/')[1]
            .toUpperCase()}`}
        >
          Get dynamic article
        </Link>
      )}
    </ul>
  );
};

export default ArticleList;
