import React, { useState, createContext } from 'react';

export const CompendiumContext = createContext();

const CompendiumProvider = ({ children }) => {
  const [currentArticle, setCurrentArticle] = useState({});
  const [activeHeading, setActiveHeading] = useState(null);

  const value = {
    currentArticle,
    setCurrentArticle,
    activeHeading,
    setActiveHeading,
  };

  return <CompendiumContext.Provider value={value}>{children}</CompendiumContext.Provider>;
};

export default CompendiumProvider;
