import React, { useState, useEffect, useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';

import ArticleList from '../ArticleList';
import RootCollectionList from '../RootCollectionList';
import { removeTrailingSlash } from '../../utils/regex';
import { CompendiumContext } from '../../context/Compendium';
import ArticleNavigation from '../ArticleNavigation';
import useAuth from '../../hooks/useAuth';

const prepareStructure = (value) =>
  value.map((item) => ({
    ...item,
    isOpen: false,
  }));

const compendiumMetadataQuery = graphql`
  query {
    compendium {
      metadata {
        structure {
          name
          path
          collections {
            name
            path
            articles {
              title
              path
              isPublic
              sections {
                id
                name
                type
              }
            }
          }
        }
      }
    }
  }
`;

const retrieveCollectionFromStructure = (structure, rootCollection, collection) => {
  const rootCollectionObject = structure.find(
    (structureRootCollection) =>
      structureRootCollection.path === `${rootCollection}/`.toLowerCase(),
  );

  if (!rootCollectionObject) {
    throw new Error('Unexisting root collection');
  }

  const collectionObject = rootCollectionObject.collections.find(
    (structureCollection) =>
      structureCollection.path === `${rootCollection}/${collection}/`.toLowerCase(),
  );

  if (!collectionObject) {
    throw new Error('Unexisting collection');
  }

  return collectionObject;
};

const getInitialChosenCollection = (...args) => {
  const emptyCollection = {
    articles: [],
    name: null,
    path: null,
  };

  try {
    return retrieveCollectionFromStructure(...args);
  } catch {
    return emptyCollection;
  }
};

const CompendiumLayout = ({ children, pathname }) => {
  const data = useStaticQuery(compendiumMetadataQuery);
  const [structure, setStructure] = useState(prepareStructure(data.compendium.metadata.structure));
  const [rootCollection, collection, article, ...extraSegments] = removeTrailingSlash(pathname)
    .match(/\/compendium\/?(.*)$/)[1]
    .split('/');
  const [chosenCollection, setChosenCollection] = useState(() =>
    getInitialChosenCollection(structure, rootCollection, collection),
  );
  const [isStructureOpen, setIsStructureOpen] = useState(false);
  const { currentArticle, setCurrentArticle } = useContext(CompendiumContext);
  const chosenArticlePath = `${rootCollection}/${collection}/${article}/`;
  const { isAdmin } = useAuth();

  useEffect(() => {
    handleCollectionChange(`${rootCollection}/${collection}/`);

    if (extraSegments.length) {
      redirectToCollection(rootCollection, collection, article);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootCollection, collection]);

  useEffect(() => {
    const currentArticle = chosenCollection.articles.find(
      (article) => article.path === chosenArticlePath,
    );

    if (!currentArticle) {
      return;
    }

    setCurrentArticle({ ...currentArticle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, chosenCollection]);

  const redirectToCollection = (rootCollection = 'html', collection = 'root', article = '') => {
    const articlePath = article && article + '/';

    navigate(`/compendium/${rootCollection}/${collection}/${articlePath}`, { replace: true });
  };

  const handleCollectionChange = (path) => {
    const [newRootCollection, newCollection] = path.split('/');

    if (newCollection !== 'root') {
      triggerStructure(`${newRootCollection}/`, true);
    }

    if (chosenCollection.path === path) {
      return;
    }

    try {
      setChosenCollection(
        retrieveCollectionFromStructure(structure, newRootCollection, newCollection),
      );
    } catch (error) {
      const mapErrorToRootCollection = {
        'Unexisting root collection': 'html',
        'Unexisting collection': newRootCollection,
      };
      const fallbackRootCollection = mapErrorToRootCollection[error.message];

      redirectToCollection(fallbackRootCollection);
      setChosenCollection(
        retrieveCollectionFromStructure(structure, fallbackRootCollection, 'root'),
      );
    }
  };

  const triggerStructure = (path, value) => {
    setStructure((currentStructure) =>
      currentStructure.map((rootCollection) =>
        rootCollection.path !== path
          ? rootCollection
          : {
              ...rootCollection,
              isOpen: typeof value !== 'undefined' ? value : !rootCollection.isOpen,
            },
      ),
    );
  };

  return (
    <div className="flex flex-nowrap">
      <nav
        className={cn(
          'lg:sticky shrink-0 flex h-dvh-fb bg-white',
          'fixed top-0 left-0 z-10 w-full lg:w-auto lg:translate-x-0 transition-transform duration-300 ease-out',
          { 'translate-x-[-92vw]': !isStructureOpen },
        )}
      >
        <RootCollectionList
          structure={structure}
          triggerStructure={triggerStructure}
          onCollectionClick={handleCollectionChange}
          chosenCollectionPath={chosenCollection.path}
        />
        <ArticleList
          articles={chosenCollection.articles}
          chosenArticlePath={chosenArticlePath}
          isStructureOpen={isStructureOpen}
          setIsStructureOpen={setIsStructureOpen}
        />
      </nav>
      <div className="grow min-w-0 min-h-dvh-fb pl-[8vw] lg:pl-0">{children}</div>
      {currentArticle.sections?.length > 1 && (
        <ArticleNavigation
          sections={currentArticle.sections || []}
          disabled={!currentArticle.isPublic && !isAdmin}
        />
      )}
    </div>
  );
};

export default CompendiumLayout;
