import React, { useState, useEffect, useMemo, createContext } from 'react';
import { BREAK_POINT } from '../constants';

export const ResponsiveContext = createContext();

const ResponsiveProvider = ({ children }) => {
  const [isMobileMd, setIsMobileMd] = useState(false);

  useEffect(() => {
    setIsMobileMd(window.innerWidth < BREAK_POINT.md);

    // TODO: rewrite to resize observer API
    const handleResize = () => {
      setIsMobileMd(window.innerWidth < BREAK_POINT.md);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const value = useMemo(
    () => ({
      isMobileMd,
    }),
    [isMobileMd],
  );

  return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>;
};

export default ResponsiveProvider;
